#moduleSidebar{
    padding: 16px 15.35px 667px 40px;
    width: 17%;
    box-shadow: 11px 12px 18.67px 0px rgba(0, 0, 0, 0.10);
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 90px;
    height: 100%;
    overflow-y: scroll;
    scrollbar-width: thin;
}
.learning-course-content {
    width: 83%;
    margin-left: 17%;
}

.course-modules .accordion-item {
    border: none !important;
    border-bottom: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color) !important;
}

.copy-link {
    background: #EDEDED;
    border-radius: 4px;
    padding: 9px 13px;
}

.copy-link input {
    background: #EDEDED;
    width: 100%;
    border: none;
    outline: none;
}

.copy-link button {
    background: #EDEDED;
    border: none;
    outline: none;
    text-transform: uppercase;
    font-weight: 600;
}

.transcript{position: relative;}

.transcript::before{
    position: absolute;
    content: " ";
    left: 0;
    right: 0;
    top: auto;
    bottom: -15px;
    border-bottom: 4px solid #146B90;
    width: 80px;
}



.transcript::after {
    border-bottom: 1px solid #ddd;
    position: absolute;
    content: " ";
    left: 0;
    right: 0;
    top: auto;
    bottom: -15px;
}

.course-modules .accordion-button:not(.collapsed) {
    color: #146B90 !important;
    border: none !important;
    background: transparent !important;
    cursor: default;
}

.course-modules .accordion-button span::after {
    filter: none !important;
    right: -80px;
}

.submodule {
    position: relative;
    padding-left: 40px;
    padding-top: 0.5rem;
    cursor: default;
}

.nested-submodule{
    cursor: default;
}

.nested-submodule .sidetab-img {
    position: absolute;
    left: 60px !important;
    top: auto;
}

.submodule .sidetab-img {
    position: absolute;
    left: 5px;
    top: auto;
}

.sidetab-img img {
    width: 65% !important;
}

.sidetab-img img.completed {
    width: 60% !important;
    filter: none;
}

/*
.nestedsubmodule:first-child {
    position: relative;
    padding-top: 1rem;
  }
  
.nestedsubmodule:first-child::after {
    width: 176px;
    background-color: #ddd;
    height: 1px;
    position: absolute;
    content: "";
    transform: translate(-70px, -6px);
    top: 0;
  }
  */

.video-time {
    min-width: 90px;
    border: 1px solid #ddd;
    height: 30px;
    border-radius: 50px;
    padding: 10px 15px;
    line-height: 0.6;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.course-module-tooltip {
    position: relative;
    display: inline-block;
    margin-right: 5px;
    border-bottom: 1px dotted black;
  }
  
  .course-module-tooltip .tooltiptext {
    visibility: hidden;
    width: 450px;
    background-color: #fff;
    color: #000;
    padding: 20px;
    position: absolute;
    z-index: 1;
    bottom: auto;
    top: 40px;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
    border-radius: 13.333px;
    filter: drop-shadow(0px 0px 53.333px rgba(0, 0, 0, 0.25));
  }
  
  .course-module-tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -176px;
    border-width: 15px;
    border-style: solid;
    border-color: #fff transparent transparent transparent;
    transform: rotate(180deg);
  }
  
  .course-module-tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .insight{
    background: #F6F6F6;
    min-width: 380px;
    padding: 20px;
    border-radius: 10.667px;
  }

  .social-icons img {
    width: 63px;
    height: 63px;
 }

 .reading img {
    padding: 0px 0;
 }

 @media screen and (max-width: 992px){
    .submodule {
        padding-left: 30px;
    }
    .sidetab-img img.completed {
        width: 48% !important;
    }
    .accordion-content .sidetab-img svg.icon {
        width: 19px;
        height: 19px;
    }
    .submodule .sidetab-img {
        left: 2px;
    }
    .reading img {
        padding: 0px 0px 5px;
    }
 }
