.banner-content {
    z-index: 999;
    position: fixed;
    top: 130px;
    right: 10px;
    bottom: 0;
}

.course-card-initials {
    background-color: #f4b532;
    border-radius: 50px;
    display: block;
    font-size: 9px;
    height: 28px;
    margin-bottom: 1rem!important;
    width: 28px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.count-circle{
    display: block;
    border: 1px solid #ccc;
    border-radius: 50%;
    width: 28px;
    height: 28px;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 0.2rem;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: var(--bs-breadcrumb-divider, ">") !important;
}
.breadcrumb .breadcrumb-item a{
    text-decoration: none;
    color: var(--3-text-colors-black, #000);
}

ol.breadcrumb {
    font-size: 14px;
}

.accordion-button:focus {
    box-shadow: none !important;
}

.accordion-button:not(.collapsed) {
    color: #000 !important;
    box-shadow: none !important;
    border: 1.333px solid var(--3-text-colors-mid-grey, #CCC) !important;
    background: var(--1-primary-colors-blue) !important;
    transition: 0.4s all;
}

.accordion-button[aria-expanded="false"] span:first-child::after{
    color: #000 !important;
    transform: rotate(0deg);
}

.accordion-button[aria-expanded="true"] {
    color: #fff !important;
    background-color: #146B90 !important;
}

.accordion-button[aria-expanded="true"] span:first-child::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
    filter: invert(1) brightness(175%);
}

.accordion-item.show .accordion-collapse.collapse {
    display: block;
}

.accordion-button::after {
  display: none;
}

.accordion-button span:first-child{
    position: relative;
}

.accordion-button span:first-child::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button span:first-child::after {
    display: inline-block;
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    top: 1px;
    position: absolute;
    left: auto;
    right: -35px;
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}

.module-list .accordion{
    --bs-accordion-border-radius: 0 !important; 
}

.module-list .accordion-item {
    border-left: 0 !important;
    border-right: 0 !important;
}

.accordion-body img.module-icon {
    width: 23px;
    height: 23px;
}

.accordion-body ul{
    padding-left: 0;
}

.accordion-body ul li{
    list-style-type: none;
}

.accordion-body ul li span svg {
    margin-right: 0.5rem;
}

.course-content div p strong{
    font-size: 20px;
    font-weight: 600;
}

.accordion-item.open .accordion-content {
    display: block !important;
}

.accordion-content {
    display: none !important;
}

.accordion-item.collapsed .accordion-header span{
    color: #146B90;
}

.accordion-content .active .selected{
    color: #146B90;
    font-weight: 600;
  }
  
.accordion-content .active .selected span {
    color: #146B90;
    font-weight: 400;
}

.accordion-content .sidetab-img svg.icon{
    fill: #949494;
    width: 23px;
    height: 23px;
}

.accordion-content .active .selected .icon .icon-color {
    fill: #146B90;
}
 /* 
.accordion-content .active .selected .sidetab-img span img {
    filter: invert(25%) sepia(46%) saturate(4471%) hue-rotate(177deg) brightness(79%) contrast(62%); 
}
*/

.accordion-content .active .selected .sidetab-img span img.completed {
    filter: none;
}

.accordion-item .accordion-header span{
    cursor: pointer;
}

.lms .accordion-item .accordion-header span{
    cursor: pointer;
    display: inline-block;
    width: 180px;
    margin-bottom: 0 !important;
}

.accordion-item.open .accordion-header span {
    color: #146B90;
}

.accordion-header {position: relative;}

.accordion-item.open .accordion-header svg {
    position: absolute;
    right: 16px;
    top: 21px;
}

.accordion-item .accordion-header svg {
    right: 25px;
    top: 30px;
    position: absolute;
    cursor: pointer;
}

.accordion-item.open .accordion-content > div {
    margin-top: 0 !important;
}

@media screen and (max-width: 992px){
    .accordion-content .sidetab-img svg.icon {
        height: 18px;
        width: 18px;
    }
}

